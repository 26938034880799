import logo from './static/Danlogonamejpg.jpg';
import './App.css';
import spacerGIF from './static/spacer.gif'
import skyline from './static/0609129-colorado-image_500.jpg'
import { Switch, Route, Link } from 'react-router-dom';

function App() {
  return (
    <table border="0" cellspacing="0" cellpadding="0" width="700" align="center" bgcolor="#FFFFFF">
        <tr>
            <td rowspan="2" valign="top" width="200">
                &nbsp;&nbsp;<img src={ logo } width="175" alt="culhane logo"/> <br/>
                <img src={ spacerGIF } width="1" height="50" alt=""/>
                <br/>
                &nbsp; :: <Link to="/home"> Home </Link>
                <p>
                    &nbsp; :: <Link to="/contact">Contact</Link>
                    <br/><br/>&nbsp;
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/><br/>
                </p>
            </td>
            <td valign="top" class="center" width="500">
                <img src={ skyline } width="500" alt="denver skyline"/><br/>
                <div align="right" class="t7"><em>&copy;Jon Camrud/ColoradoImage.com</em></div>
                <br/><br/>
            </td>
        </tr>
        <tr>
            <td valign="top">
                <Switch>
                  <Route exact path="/contact">
                    <p>Daniel J. Culhane LLC </p>
                    <p>1601 Blake St, Suite 300<br/>
                        Denver, CO 80202</p>
                    <p>o: 303-945-2070<br/>
                        c: 303-478-2649</p>
                    <p><a href="mailto:Dan@CulhaneLaw.com?subject=Inquiry from Website">Dan@CulhaneLaw.com</a></p>
                    <br/><br/>
                    <span class="t7">Disclaimer: The use of the Internet or communication by email through this site or any individual member of the firm does not establish an attorney-client relationship. Confidential or time-sensitive information should not be sent through this site. By using this site you agree that you understand and agree with the foregoing statements.</span><br/>
                    <br/>
                  </Route>
                  <Route path='/'>
                    <table width="80%" border="0" cellspacing="2" cellpadding="2">
                        <tr>
                            <td><strong>ABOUT THE FIRM:</strong><br/>
                                Daniel J. Culhane LLC was founded in 2005.&nbsp; The firm specializes in commercial and real estate matters, including transactions and litigation.&nbsp; The firm also handles estate and probate matters.
                                <p>Dan has practiced law for over 30 years and founded Daniel J. Culhane LLC in 2005.&nbsp; He is a Certified Mediator and a member of both the Colorado Bar Association and the Denver Bar Association.<br/>&nbsp;
                                    <br/>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>ABOUT DANIEL J. CULHANE:</strong><br/>
                                Dan received his J.D. from Stanford Law School in 1992.&nbsp; He graduated from Stanford University with a B.A. in Classics (Greek and Latin) in 1989 after studying at the Intercollegiate Center for Classical Studies in Rome, Italy.
                                <p>Dan is a native of Denver, Colorado and a graduate of Denver&rsquo;s East High School. <br/></p>
                            </td>
                        </tr>
                    </table>
                  </Route>
                </Switch>
                <br/>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="t7">
                <br/>
                Copyright 2022 Daniel J. Culhane LLC.<br/>
                All rights reserved.<br/>
                <br/>&nbsp;
            </td>
        </tr>
    </table>

  );
}

export default App;
